import React from 'react'

import Gallery from '../../components/common/gallery'

import { PageHeader } from '../../components/common/styles'

const Luca = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((number) => (
        `https://s3.amazonaws.com/valentina-site/portfolio_images/luca/luca${number}.jpg`
      ))
    }
    navigationColor='#c3c6cc'
    fromText='Portfolio'
    fromLink='/portfolio'
    moreInfo={{
      linkText: 'How It Works',
      content:
        <LucaHowItWorks />
    }}
  />
)

const LucaHowItWorks = () => (
  <div style={{ display: 'flex', margin: '20px', marginLeft: 0 }}>
    <div style={{
      width: '34%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <img style={{ width: '50%' }} src='https://s3.amazonaws.com/valentina-site/portfolio_images/luca/how_it_works/bus1.jpg' />
      <img style={{ width: '50%' }} src='https://s3.amazonaws.com/valentina-site/portfolio_images/luca/how_it_works/bus2.jpg' />
      <img style={{ width: '50%' }} src='https://s3.amazonaws.com/valentina-site/portfolio_images/luca/how_it_works/bus1.jpg' />
    </div>
    <div style={{ width: '66%' }}>
      <PageHeader style={{ color: '#f9b217' }}>
        Luca Draws and Design
    </PageHeader>
      <p>
        Luca is a small, customized kidswear collection based on the drawings of Luca Staudinger, 8 years old.
        The mission of Luca is to reconnect kids to art in this increasingly digital world.
    </p>
      <p>
        This by allowing parents to turn their kids’ drawings and artwork into beautiful gifts,
        we are encouraging the sharing of art with friends and family members.
        In doing so, we are encouraging children to do more art and to share
        the joy of art through our contemporary tools of communication.
    </p>
      <p>
        I was motivated to build this capsule collection after seeing my little cousin draw some
        beautiful pieces of art and then going out to find a way to share this art with the
        rest of our family. To create an MVP, I retouched and screen printed his drawings
        to create clothes - t-shirts, jumpers, and pillows.
    </p>
      <p>
        I then created an online campaign whereby I sold these same pieces of clothes to my
        family and friends to raise money for a kids charity. This was also a great success
        and we raised $200 for the charity.
    </p>
      <p>
        I realized that tech implementation can lower the barriers needed to design such products
        and enable more families to share the gift of art with those that they love.
        Automatic solutions in our digital era are also a possibility to express ourselves.
    </p>
      <p style={{ marginBottom: 0 }}>
        With the idea in hand my friend Oliver created Jellyfish, an iOS and Android app that
        parents use to take photos of their children’s drawings - The app then automatically
        touches up these drawings and turns them into beautiful personalized products such as t-shirts,
        throw cushions and tote bags.
    </p>
    </div>
  </div >
)

export default Luca